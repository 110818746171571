// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FOOTER */
footer {
    border-top: 1px solid #2f2f2f;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1%;
}


footer a{
    padding:0.86em;
    font-size: 1.12rem;
    text-decoration: none;
    color: var(--a-alternate);
}

footer a:hover {
    padding:0.86em;
    font-size: 1.22rem;
    color: var(--a-alternate);
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Footer.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;;AAGA;IACI,cAAc;IACd,kBAAkB;IAClB,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":["/* FOOTER */\nfooter {\n    border-top: 1px solid #2f2f2f;\n}\n\n.footer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding: 1%;\n}\n\n\nfooter a{\n    padding:0.86em;\n    font-size: 1.12rem;\n    text-decoration: none;\n    color: var(--a-alternate);\n}\n\nfooter a:hover {\n    padding:0.86em;\n    font-size: 1.22rem;\n    color: var(--a-alternate);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
