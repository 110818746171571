// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* BACKGROUND COLORS */
--main-bg: #f7f6f4;
--item-bg:  #f7f5f2;

/* SECOND FONT - NO SERIF */
--feature-sans-serif: 'Lato', sans-serif;

/* HEADER COLOR */
--h1-header: #101646;
--h1-alternate: #1E3C32;

/* LINK COLOR */
--a-standard: #5b1700;
--a-alternate: #1E3C32;
--a-feature:  #8d4918;

/* GLOBAL COLORS */
--grey-font: #151515;
--contact-form: #474544;
}


* {
  box-sizing: border-box;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont;
  font-family: 'Cormorant', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB,kBAAkB;AAClB,mBAAmB;;AAEnB,2BAA2B;AAC3B,wCAAwC;;AAExC,iBAAiB;AACjB,oBAAoB;AACpB,uBAAuB;;AAEvB,eAAe;AACf,qBAAqB;AACrB,sBAAsB;AACtB,qBAAqB;;AAErB,kBAAkB;AAClB,oBAAoB;AACpB,uBAAuB;AACvB;;;AAGA;EACE,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,8CAA8C;EAC9C,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;EAClC,gCAAgC;AAClC;;AAEA;EACE;aACW;AACb","sourcesContent":[":root {\n  /* BACKGROUND COLORS */\n--main-bg: #f7f6f4;\n--item-bg:  #f7f5f2;\n\n/* SECOND FONT - NO SERIF */\n--feature-sans-serif: 'Lato', sans-serif;\n\n/* HEADER COLOR */\n--h1-header: #101646;\n--h1-alternate: #1E3C32;\n\n/* LINK COLOR */\n--a-standard: #5b1700;\n--a-alternate: #1E3C32;\n--a-feature:  #8d4918;\n\n/* GLOBAL COLORS */\n--grey-font: #151515;\n--contact-form: #474544;\n}\n\n\n* {\n  box-sizing: border-box;\n  line-height: 1;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  margin: auto;\n  font-family: -apple-system, BlinkMacSystemFont;\n  font-family: 'Cormorant', serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--main-bg);\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
